.product-section {
  margin-top: 20px;
  display: flex;
  flex-direction: column;

  &__items {
    width: 100%;
    display: flex;

    flex-direction: column;
  }
}

.product-overview {
  display: grid;

  @media (min-width: map-get($map: $screen-sizes, $key: xl)) {
    margin: 0;
    width: 100%;
    grid-template-areas: "menu . products";
    grid-template-columns: 260px 15px auto;
  }

  &__cell {
    &--menu {
      display: none;
      grid-area: menu;

      @media (min-width: map-get($map: $screen-sizes, $key: xl)) {
        display: flex;
        flex-direction: column;
        height: 100%;
        background-color: $primary;
      }
    }

    &--products {
      grid-area: products;
    }
  }
}
