.ingredient {
  width: calc(50% - 5px);
  display: flex;
  flex-direction: row;

  &:nth-of-type(0n) {
    padding-right: 5px;
  }
  &:nth-of-type(1n) {
    padding-left: 5px;
  }

  &__label {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
  }

  &__input {
    position: relative;
    width: 20px;
    height: 20px;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 0;
    font-size: 0;
    appearance: none;
    box-shadow: none;
    flex-shrink: 0;
    margin: 0;

    &:checked:after {
      opacity: 1;
      transform: rotate(-45deg) scale(1);
    }

    &:checked:before {
      background-color: darken($primary, 10%);
    }

    &:focus {
      outline: none;
    }

    &::before {
      content: "";
      position: absolute;
      width: 20px;
      height: 20px;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      border: 1px solid $primary;
      border-radius: 2px;
      background: $white;
    }

    &::after {
      content: "";
      position: absolute;
      width: 10px;
      height: 5px;
      border-bottom: 2px solid $white;
      border-left: 2px solid $white;
      transform: rotate(-45deg) scale(0);
      transition: transform ease 0.25s;
      will-change: transform;
      opacity: 0;
    }
  }

  &__copy {
    font-family: $poppins;
    color: $white;
    font-size: 12px;
    font-weight: bold;
    margin-left: 5px;
  }
}
