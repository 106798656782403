.menu {
  position: fixed;
  width: 100%;
  height: calc(100vh - 91px);
  top: 75px;
  bottom: 0;
  margin: 0;
  background-color: $primary;
  font-family: $poppins;
  color: $white;
  overflow-y: auto;
  padding: 15px 25px 90px 25px;
  display: flex;
  flex-direction: column;

  @media (min-width: map-get($map: $screen-sizes, $key: xl)) {
    top: 120px;
    width: 210px;
  }

  &__logo {
    width: 100%;
    height: 130px;
    display: flex;
    justify-content: flex-start;

    @media (min-width: map-get($map: $screen-sizes, $key: xl)) {
      display: none;
    }
  }

  &__image {
    height: 100px;
    object-fit: cover;
  }

  &__title {
    font-size: 28px;
    font-weight: bold;
    margin: 0;

    @media (min-width: map-get($map: $screen-sizes, $key: xl)) {
      margin-top: 5px;
    }

    & + .menu__divider {
      margin-top: 10px;
    }
  }

  &__divider {
    width: calc(100% - 50px);
    height: 2px;
    background: rgba($white, 0.5);
  }

  &__items {
    list-style-type: none;
    padding: 0;
  }

  &__link {
    color: $white;
    text-decoration: none;

    &:hover,
    &:visited,
    &:active {
      color: $white;
    }
  }

  &__item {
    text-transform: uppercase;
    font-weight: bold;
    cursor: pointer;
    font-size: 18px;
    line-height: 45px;
  }
}
