.product-block {
  width: calc(100% - 50px);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-self: center;
  padding-bottom: 1rem;
  border-bottom: 1px solid $grey--light;
  margin-top: 25px;
  font-family: $poppins;
  font-size: 14px;
  font-weight: bold;
  color: $secondary;

  @media (min-width: map-get($map: $screen-sizes, $key: md)) {
    width: calc(100% - 30px);
    height: 90px;
    border-bottom: 0;
    padding: 20px 15px;
    margin-top: 0;
    box-shadow: 0 4px 16px rgba($secondary, 0.08);
  }

  &__image-frame {
    width: 23%;

    @media (min-width: map-get($map: $screen-sizes, $key: md)) {
      width: 90px;
    }
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    font-size: 8px;

    @media (min-width: map-get($map: $screen-sizes, $key: md)) {
      width: 90px;
      height: 90px;
    }
  }

  &__content {
    width: 67%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 15px;

    @media (min-width: map-get($map: $screen-sizes, $key: md)) {
      justify-content: flex-start;
    }
  }

  &__name {
    margin: 0;
  }

  &__shopping {
    width: 10%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    @media (min-width: map-get($map: $screen-sizes, $key: md)) {
      justify-content: space-between;
    }
  }

  &__expand,
  &__collapse {
    width: 31px;
    height: 31px;
    box-shadow: 0 12px 16px rgba($secondary, 0.1);
    border-radius: 10px;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &:hover {
      opacity: 0.5;
      transition: opacity 0.5s;
    }
  }

  &__expand {
    background-color: $primary;
  }

  &__collapse {
    background-color: $white;
  }

  &__icon {
    width: 16px;
    height: 16px;
    fill: $white;
    transition-property: transform;
    transition-duration: 0.2s;
    transition-timing-function: ease-out;

    &--cross {
      fill: $black;
      transform: rotate(45deg);
    }
  }

  &__price + &__expand {
    margin-top: 15px;
  }

  &__price + &__collapse {
    margin-top: 15px;
  }
}
