.actions {
  position: fixed;
  bottom: 0;
  display: flex;
  width: calc(100% - 16px);
  height: 96px;
  justify-content: space-between;
  align-items: center;
  background: $white;
  z-index: 3;

  @media (min-width: map-get($map: $screen-sizes, $key: md)) {
    width: 350px;
    align-self: flex-end;
    position: initial;
  }

  &__next,
  &__previous {
    width: calc(50% - 4px);
    border-radius: 10px;
    box-shadow: 0 12px 16px rgba($secondary, 0.1);
    display: flex;
    justify-content: center;
    align-self: center;
    text-transform: uppercase;
    text-decoration: none;
    font-family: $poppins;
    font-size: 14px;
    font-weight: 600;
    padding: 16px 0;
    cursor: pointer;
    &:hover,
    &:visited,
    &:focus,
    &:active {
      text-decoration: none;
    }

    &:hover {
      opacity: 0.5;
      transition: opacity 0.5s;
    }

    &--invalid {
      opacity: 0.5;
      cursor: initial;
    }
  }

  &__next {
    background-color: $primary;
    color: $white;
  }

  &__previous {
    color: $secondary;
    background-color: rgba($secondary, 0.1);

    &:before {
      content: '';
      position: absolute;
      left: 15px;
      width: 8px;
      height: 8px;
      margin: 5px;
      border-top: $secondary 1px solid;
      border-left: $secondary 1px solid;
      transform: rotate(-45deg);
    }
  }
}
