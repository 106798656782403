.form {
  &__item {
    width: 100%;
    margin-bottom: 20px;
  }

  &__label {
    display: none;
  }

  &__input {
    width: calc(100% - 30px);
    padding: 15px;
    font-size: 15px;
    font-family: $poppins;
    border: none;
    box-shadow: 0 4px 16px rgba($secondary, 0.08);
    border-radius: 10px;

    &::placeholder {
      opacity: 0.5;
    }

    &:focus {
      border: $primary 2px solid;
      outline: none;
    }

    &--invalid {
      border: $form-invalid 2px solid;
    }
  }
}
