.product-configuration {
  width: 100%;
  background-color: $primary;
  padding-bottom: 20px;
  margin: 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  border-radius: 0 0 10px 10px;
  box-shadow: 0 4px 16px rgba($secondary, 0.08);

  &__title {
    margin: 0;
    font-family: $poppins;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: bold;
    color: $white;
  }

  &__section {
    margin-top: 15px;
    width: calc(100% - 50px);

    & + & {
      margin-top: 25px;
    }
  }

  &__items {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &__title + &__items {
    margin-top: 15px;
  }

  &__select {
    position: relative;
    width: calc(100% - 50px);
    padding: 15px;
    margin-top: 15px;
    font-size: 15px;
    font-family: $poppins;
    color: rgba($secondary, 0.57);
    border: none;
    box-shadow: 0 4px 16px rgba($secondary, 0.08);
    border-radius: 10px;
    background: $white;
    text-align: left;

    &:focus {
      border: $primary 2px solid;
      outline: none;
    }

    &:before {
      content: '';
      position: absolute;
      right: 20px;
      width: 8px;
      height: 8px;
      margin: 5px;
      border-bottom: $secondary 1px solid;
      border-right: $secondary 1px solid;
      transform: rotate(45deg);
    }

    &:after {
      content: '';
      position: absolute;
      height: 24px;
      top: 15px;
      width: 1px;
      right: 50px;
      background-color: $secondary;
    }

    &--active {
      .select__options {
        position: absolute;
        padding: 0;
        height: auto;
        align-items: center;
        opacity: 1;
        transform: scale(1);
        background: #fff;
        visibility: visible;
        max-height: 100px;
        overflow: auto;
        border-radius: 10px;
        left: 0;
        width: 100%;
      }

      &:before {
        transform: rotate(225deg);
        top: 20px;
      }
    }
  }
}
