.product-actions {
  margin-top: 20px;
  width: calc(100% - 25px);
  height: 52px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  @media (min-width: map-get($map: $screen-sizes, $key: md)) {
    width: calc(100% - 50px);
  }

  &__copy {
    font-family: $poppins;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: bold;
    color: $white;
  }

  &__add-to-cart {
    width: 52px;
    height: 52px;
    background-color: darken($primary, 10%);
    border-radius: 10px;
    border: none;
    box-shadow: 0 12px 16px rgba($secondary, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &:hover {
      opacity: 0.5;
      transition: opacity 0.5s;
    }
  }

  &__icon {
    width: 24px;
    height: 20px;
  }

  &__configuration {
    display: flex;
    flex-direction: row;
    height: 100%;
    align-self: center;
    background: $white;
    border-radius: 10px;
  }

  &__add,
  &__remove {
    background: transparent;
    border: none;
    width: 52px;
    cursor: pointer;

    &:hover {
      opacity: 0.5;
      transition: opacity 0.5s;
    }

    &:before {
      font-size: 15px;
      font-weight: bold;
    }
  }

  &__add {
    &::before {
      content: '+';
    }
  }

  &__remove {
    &::before {
      content: '-';
    }
  }

  &__quantity {
    width: 46px;
    border-top: none;
    border-bottom: none;
    border-left: $grey--light 1px solid;
    border-right: $grey--light 1px solid;
    font-family: $poppins;
    font-size: 15px;
    font-weight: bold;
    text-align: center;
  }
}
