.grid {
  display: grid;

  &--content {
    grid-template-areas: 'navigation' 'content';
    grid-template-rows: 75px auto;

    @media (min-width: map-get($map: $screen-sizes, $key: xl)) {
      grid-template-rows: 120px auto;
    }
  }

  &--content &__cell {
    &--content {
      grid-area: content;
      position: relative;
      max-height: calc(100vh - 91px);
      width: calc(100% - 16px);
      margin: 0 8px;
      overflow: auto;

      display: grid;
      grid-template-areas: 'centered';

      @media (min-width: map-get($map: $screen-sizes, $key: xl)) {
        margin: 0;
        width: 100%;
        height: calc(100vh - 120px);
        grid-template-columns: auto 1100px auto;
        grid-template-areas: '. centered .';
      }
    }

    &--content-full-height {
      height: auto;
    }

    &--navigation {
      grid-area: navigation;

      display: grid;
      grid-template-areas: 'navbar';

      @media (min-width: map-get($map: $screen-sizes, $key: xl)) {
        grid-template-columns: auto 1100px auto;
        grid-template-areas: '. navbar .';
      }
    }

    &--centered {
      grid-area: centered;
    }
  }
}
