.select {
  width: 100%;
  display: flex;
  flex-direction: column;
  font-family: $poppins;

  &__trigger {
    position: relative;
    height: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;

    &:after {
      content: "";
      position: absolute;
      width: 0;
      height: 0;
      top: 50%;
      right: 1.5rem;
      transform: translateY(-50%);
      transition-duration: 0.2s;
      transition-property: transform;
      transition-timing-function: ease-out;
    }
  }

  &__options {
    height: 0;
    opacity: 0;
    visibility: hidden;
    transform: scale(0.9);
    transition-property: transform, opacity, visibility;
    transition-duration: 0.2s;
    transition-timing-function: ease-out;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  &__option {
    width: 100%;
    padding: 1rem 1.5rem;
    cursor: pointer;
    user-select: none;
    background: transparent;
    border: none;
    font-family: $poppins;
  }

  &__option:not(:last-child) {
    border-bottom: 1px solid $grey--light;
  }

  &__option--active {
    font-weight: 600;
  }

  &--active {
    .select__trigger:after {
      transform: rotate(180deg);
      margin-top: -0.35rem;
    }
  }
}
