.product {
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: map-get($map: $screen-sizes, $key: md)) {
    width: calc(50% - 20px);
    margin-bottom: 20px;

    &:nth-of-type(odd) {
      margin-right: 10px;
    }

    &:nth-of-type(even) {
      margin-left: 10px;
    }
  }
}
