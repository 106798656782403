.basket {
  display: flex;
  flex-direction: column;

  &__title {
    font-family: $poppins;
    color: $secondary;
    font-size: 36px;
    margin: 0;
    text-align: left;
    font-weight: bold;

    @media (min-width: map-get($map: $screen-sizes, $key: md)) {
      text-align: center;
      margin-bottom: 25px;
    }
  }

  &__orders {
    width: 100%;
    max-width: 1100px;
    display: flex;
    flex-direction: column;
  }
}
