.product-category {
  &__title {
    font-size: 18px;
    font-family: $poppins;
    color: rgba($primary, 0.57);
    text-transform: uppercase;
    font-weight: bold;
    margin: 0 0 15px;
  }

  &__items {
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 10px;
    box-shadow: 0 4px 16px rgba($secondary, 0.08);

    @media (min-width: map-get($map: $screen-sizes, $key: md)) {
      flex-direction: row;
      flex-wrap: wrap;
      box-shadow: none;
      border-radius: 0;
    }
  }

  & + & {
    margin-top: 40px;
  }
}
