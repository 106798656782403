.personal-details {
  margin-top: 25px;
  width: calc(100% - 48px);
  align-self: center;
  &__title {
    font-size: 18px;
    font-family: $poppins;
    color: rgba($primary, 0.57);
    text-transform: uppercase;
    font-weight: bold;
    margin: 0 0 15px;
  }
}
