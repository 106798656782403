.fetch-time {
  margin: 25px 0;
  width: calc(100% - 48px);
  align-self: center;
  &__title {
    font-size: 18px;
    font-family: $poppins;
    color: rgba($primary, 0.57);
    text-transform: uppercase;
    font-weight: bold;
    margin: 0;
  }

  &__explanation {
    font-family: $poppins;
    color: $dark-greyish-blue;
    font-style: italic;
    font-size: 12px;
    margin-bottom: 15px;
  }

  &__select {
    position: relative;
    padding: 15px;
    margin-top: 15px;
    font-size: 15px;
    font-family: $poppins;
    color: rgba($secondary, 0.57);
    border: none;
    box-shadow: 0 4px 16px rgba($secondary, 0.08);
    border-radius: 10px;
    background: transparent;
    text-align: left;

    &:focus {
      border: $primary 2px solid;
      outline: none;
    }

    &:before {
      content: '';
      position: absolute;
      right: 20px;
      width: 8px;
      height: 8px;
      margin: 5px;
      border-bottom: $secondary 1px solid;
      border-right: $secondary 1px solid;
      transform: rotate(45deg);
    }

    &:after {
      content: '';
      position: absolute;
      height: 24px;
      top: 15px;
      width: 1px;
      right: 50px;
      background-color: $secondary;
    }

    & + & {
      margin-top: 20px;
    }

    &--active {
      .select__options {
        position: absolute;
        padding: 0;
        height: auto;
        align-items: center;
        opacity: 1;
        transform: scale(1);
        background: #fff;
        visibility: visible;
        max-height: 100px;
        overflow: auto;
        border-radius: 10px;
        left: 0;
        width: 100%;
      }

      &:before {
        transform: rotate(225deg);
        top: 20px;
      }
    }

    &--calendar {
      width: 100%;
    }
  }

  &__slots {
    margin-top: 25px;
    display: flex;
    flex-direction: column;
  }

  &__time-slot {
    position: relative;
    padding: 15px;
    font-size: 15px;
    font-family: $poppins;
    color: $secondary;
    border: none;
    box-shadow: 0 4px 16px rgba($secondary, 0.08);
    border-radius: 10px;
    background: transparent;
    text-align: left;
    cursor: pointer;
    &--active {
      background: $primary;
      color: $white;
    }

    &:hover {
      background: $primary;
      color: $white;
      opacity: 0.5;
      transition: opacity 0.5s;
    }
  }
}
