@import url("https://fonts.googleapis.com/css?family=Open+Sans|Roboto&display=swap");

// fonts
$open-sans: "Open sans", sans-serif;
$roboto: "Roboto", sans-serif;
$poppins: "Poppins";

// colors
$black: #000;
$white: #fff;
$primary: #4042e3;
$secondary: #455b63;
$grey: #707070;
$grey--light: #f4f4f6;
$cyan-blue--medium-light: #959dad;
$dark-greyish-blue: #78849e;
$form-invalid: #ffcccc;
$red: #ff0000;

// screen sizes
$screen-sizes: (
  xs: 375px,
  sm: 480px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1600px,
  xxxl: 1920px,
);
