@font-face {
  font-family: $poppins;
  font-style: normal;
  font-weight: 400;
  src: url("./fonts/poppins-v9-latin-regular.eot");
  src: local("Poppins Regular"), local("Poppins-Regular"),
    url("./fonts/poppins-v9-latin-regular.eot?#iefix") format("embedded-opentype"),
    url("./fonts/poppins-v9-latin-regular.woff2") format("woff2"),
    url("./fonts/poppins-v9-latin-regular.woff") format("woff"),
    url("./fonts/poppins-v9-latin-regular.ttf") format("truetype"),
    url("./fonts/poppins-v9-latin-regular.svg#Poppins") format("svg");
}
@font-face {
  font-family: $poppins;
  font-style: normal;
  font-weight: 700;
  src: url("./fonts/poppins-v9-latin-700.eot");
  src: local("Poppins Bold"), local("Poppins-Bold"),
    url("./fonts/poppins-v9-latin-700.eot?#iefix") format("embedded-opentype"),
    url("./fonts/poppins-v9-latin-700.woff2") format("woff2"), url("./fonts/poppins-v9-latin-700.woff") format("woff"),
    url("./fonts/poppins-v9-latin-700.ttf") format("truetype"),
    url("./fonts/poppins-v9-latin-700.svg#Poppins") format("svg");
}
@font-face {
  font-family: $poppins;
  font-style: normal;
  font-weight: 800;
  src: url("./fonts/poppins-v9-latin-800.eot");
  src: local("Poppins ExtraBold"), local("Poppins-ExtraBold"),
    url("./fonts/poppins-v9-latin-800.eot?#iefix") format("embedded-opentype"),
    url("./fonts/poppins-v9-latin-800.woff2") format("woff2"), url("./fonts/poppins-v9-latin-700.woff") format("woff"),
    url("./fonts/poppins-v9-latin-800.ttf") format("truetype"),
    url("./fonts/poppins-v9-latin-800.svg#Poppins") format("svg");
}
