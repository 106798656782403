.calendar {
  border-top: 2px solid $primary;
  min-width: 150px;
  font-family: $poppins;
  z-index: 0;

  &__header {
    border-left: 1px solid $grey--light;
    border-right: 1px solid $grey--light;
  }

  &__pager {
    height: 40px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    font-size: 9px;
  }

  &__previous,
  &__next {
    padding: 10px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    cursor: pointer;

    &--disabled {
      visibility: hidden;
      pointer-events: none;
    }

    &:hover {
      color: $primary;
    }
  }

  &__previous::before {
    @extend %fa-icon;
    @extend .fas;

    content: fa-content($fa-var-chevron-left);
  }

  &__next::after {
    @extend %fa-icon;
    @extend .fas;

    content: fa-content($fa-var-chevron-right);
  }

  &__current-month {
    display: inline-block;
    font-weight: bold;
    text-align: center;
    text-transform: capitalize;
  }

  &__day-labels {
    width: 100%;
    display: flex;
  }

  &__day-label {
    width: calc(100% / 7);
    display: flex;
    justify-content: center;
    font-size: 9px;
    color: $secondary;
    text-transform: uppercase;
  }

  &__body {
    border: 1px solid $grey--light;
  }

  &__week {
    display: flex;

    & + & {
      border-top: 1px solid $grey--light;
    }
  }

  &__day {
    position: relative;
    width: calc(100% / 7);
    height: 30px;
    display: flex;
    font-size: 10px;
    font-weight: bold;
    color: $grey;
    box-sizing: border-box;
    cursor: pointer;

    & + & {
      border-left: 1px solid $grey--light;
    }

    &--disabled {
      color: $grey;
      background: $grey--light;
      cursor: default;
    }

    &--selected {
      color: $white;
      background: $primary;
    }

    &--selected#{&}--disabled {
      color: transparentize($white, 0.5);
    }

    &--outside-month {
      color: $grey;
    }
  }

  &__day-content {
    position: relative;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
  }

  &__day--selected &__day-content,
  &__day--today &__day-content {
    position: absolute;
    top: -1px;
    left: -1px;
    box-sizing: content-box;
    border: 1px solid $primary;
  }

  &__day--today &__day-content {
    z-index: 2;
  }
}
