.navigation {
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: space-between;
  font-family: $poppins;
  font-weight: 800;
  text-transform: uppercase;
  background: $white;
  padding: 8px;
  grid-area: navbar;

  &__toggle,
  &__basket {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 52px;
    height: 52px;
    border: none;
    border-radius: 10px;
    box-shadow: 0 12px 16px rgba($secondary, 0.1);
    cursor: pointer;

    &:hover {
      opacity: 0.5;
      transition: opacity 0.5s;
    }
  }

  &__icon {
    width: 16px;
    height: 16px;
    padding: 4px;
    transition-property: transform;
    transition-duration: 0.2s;
    transition-timing-function: ease-out;

    &--collapse {
      fill: $black;
      transform: rotate(45deg);
    }
  }

  &__toggle {
    background-color: $white;

    @media (min-width: map-get($map: $screen-sizes, $key: xl)) {
      display: none;
    }
  }

  &__basket {
    position: relative;
    background-color: $primary;

    > .navigation__icon {
      width: 24px;
      height: 21px;
      padding: 0;
    }
  }

  &__number-of-items {
    position: absolute;
    width: 13.5px;
    height: 13.5px;
    top: 7px;
    right: 7px;
    padding: 0;
    display: flex;
    flex-grow: 0;
    justify-content: center;
    align-items: center;
    font-family: $poppins;
    font-size: 8.5px;
    font-weight: bold;
    color: $white;
    border-radius: 50px;
    border: 1px solid $red;
    text-shadow: 0px 0px 2px rgba(0, 0, 0, 0.8);
    background: $red;
  }

  &__home {
    text-decoration: none;
    color: $grey;
    text-transform: uppercase;
    display: flex;
    flex-direction: column;
    justify-content: center;
    &:hover,
    &:visited,
    &:focus,
    &:active {
      text-decoration: none;
      color: $grey;
    }
  }

  &__logo {
    display: none;

    @media (min-width: map-get($map: $screen-sizes, $key: xl)) {
      display: flex;
      justify-content: flex-start;
      width: 100px;
      height: 100px;
    }
  }

  &__image {
    height: 100px;
    object-fit: cover;
  }
}
