.checkout {
  display: flex;
  flex-direction: column;

  &__title {
    font-family: $poppins;
    color: $secondary;
    font-size: 36px;
    margin: 0 0 15px;
    text-align: left;
    font-weight: bold;

    @media (min-width: map-get($map: $screen-sizes, $key: md)) {
      text-align: center;
    }
  }

  &__navigation {
    display: flex;
    flex-direction: row;
    align-self: center;
    width: 100%;

    @media (min-width: map-get($map: $screen-sizes, $key: md)) {
      max-width: 380px;
    }
  }

  &__navigation-item {
    width: calc(100% / 3);
    height: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: $poppins;
    font-size: 13px;
    color: $cyan-blue--medium-light;
    text-align: center;
    text-transform: uppercase;
    border-top: 1px solid $grey--light;
    border-bottom: 1px solid $grey--light;
    border-left: 1px solid $grey--light;
    border-right: none;
    background: transparent;
    text-decoration: none;

    @media (min-width: map-get($map: $screen-sizes, $key: md)) {
      border-top: none;
      border-bottom: none;
      &:first-of-type {
        border-left: none;
      }

      &:last-of-type {
        border-right: none;
      }
    }

    &:last-of-type {
      border-right: 1px solid $grey--light;

      @media (min-width: map-get($map: $screen-sizes, $key: md)) {
        border-right: none;
      }
    }

    &--active {
      position: relative;
      color: $grey;
      padding-bottom: 0;

      &:after {
        content: "";
        position: absolute;
        width: 100%;
        height: 7px;
        bottom: 0;
        background-color: $primary;
      }
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    margin-bottom: 95px;
    width: 100%;
    max-width: 620px;
    align-self: center;
    & + .actions {
      background-color: $white;
    }
  }

  &__check-block {
    padding: 15px;
    margin-top: 15px;
    font-size: 15px;
    font-family: $poppins;
    color: $secondary;
    border: none;
    box-shadow: 0 4px 16px rgba($secondary, 0.08);
    border-radius: 10px;
  }
}
